import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyClovrnRl9htJMzzuE1Z9pEhKLnQmWEcHM",
  authDomain: "pushnotification-b40e9.firebaseapp.com",
  projectId: "pushnotification-b40e9",
  storageBucket: "pushnotification-b40e9.appspot.com",
  messagingSenderId: "406118300083",
  appId: "1:406118300083:web:5f7d2cad458541225af3c6"
};  

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export {auth}
