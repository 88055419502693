import React, { useState,useEffect  } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useForm, Controller  } from 'react-hook-form';

export default function FormPropsTextFields(props) {
    
    const { register, handleSubmit, control, setValue   } = useForm();

    const [fromdata, setFromdata] = React.useState(null);
    const [todata, setTodata] = React.useState(null);

    useEffect(() => {  
        const values = []

        props.dataSet(values)

    },[])





    const onSubmit = (data) => {
   
        props.emptydata()
        const cardholderName = data.name && data.surname ? data.name + " " + data.surname : null
        var newData = {
            cardholderName : data.name && data.surname ? data.name + " " + data.surname : undefined,
            last4 : data.last4 ? data.last4 : undefined,
            first6 : data.first6 ? data.first6 : undefined,
            clickid: data.clickid ? data.clickid : undefined,
            email: data.email ? data.email : undefined,
            from: data.from ? data.from : undefined,
            to: data.to ? data.to : undefined,
        }

        // http://localhost:2222/ccare/getUser

        fetch("https://payment.trkmyclick.com/ccare/getUser", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
        .then(response => response.json()) 
        .then(response => {
            
            props.dataSet(response)
        })
        .catch((err)=>{
            console.log(err)
        })
        return true
    }

    

  return (
      <>
    <form onSubmit={handleSubmit(onSubmit)}>
       <Controller
        name={"textValue"}
        control={control}
        render={({ field: { onChange, value } }) => (
            <>
            <FormLabel component="legend">Search for a Customer</FormLabel>
            <TextField style={{ width: "200px", margin: "5px" }} {...register('name')} id="name" label="Name" type="search" />
            <TextField style={{ width: "200px", margin: "5px" }} {...register('surname')} id="surname" label="Surname" type="search" />
            <TextField style={{ width: "200px", margin: "5px" }} {...register('email')} id="email" label="email" type="search" />
            <TextField style={{ width: "200px", margin: "5px" }} {...register('last4')} id="last4" label="Last_4" type="search" />
            <TextField style={{ width: "200px", margin: "5px" }} {...register('first6')} id="first6" label="First_6" type="search" />
            <TextField style={{ width: "200px", margin: "5px" }} {...register('clickid')} id="clickid" label="Clickid" type="search" />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label="Date From"
                    id="from"
                    inputFormat="yyyy-MM-dd"
                    value={fromdata}
                    onChange={(value) => {setValue('from', value);setFromdata(value)}}
                    renderInput={(params) => <TextField   {...params} />}
                />
                <DesktopDatePicker
                label="Date To"
                id="to"
                inputFormat="yyyy-MM-dd"
                value={todata}
                onChange={(value) => {setValue('to', value);setTodata(value)}}
                renderInput={(params) => <TextField   {...params} />}
            />
            </LocalizationProvider>
            <Button variant="contained" size="large" type="submit" >Search</Button>
            </>
        )}/>
        
           
       
        
    
    </form>
    </>
  );
}