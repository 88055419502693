import React, { useState,useEffect  } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import Grid from '@mui/material/Grid';
import FormCcare from "./searchFormCcare"
import '../css/customerCare.css'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function Ccare() {
      const [rows, setRow] = useState([]);
      const [transactionsRows, setTransactionsRows] = useState([]);
      const [open, setOpen] = useState(false);
      const [popup, setPopup] = useState({title:"",body:""});

      function unsubUser(user){
        
        for(let i=0;i<transactionsRows.length;i++){
          
          if(transactionsRows[i].data_unsub){
            setPopup({...{title:"Already unsuscribed",body:"This user is already unsuscribed"}})
            setOpen(true)
            return
          }
        }
        return fetch("https://payment.trkmyclick.com/orders/unsubUser",{ 
          method: 'post', 
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({clickid:user.clickid})
        })
        .then(res => res.json())
        .then(response => {
            if(response.status == "ok"){
              setPopup({...{title:"Success",body:"User unsuscribed"}})
              setOpen(true)
            }else{
              setPopup({...{title:"Ko",body:"Something goes wrong"}})
              setOpen(true)
            }
           return response
        })
        
       
      }

    
      function populateTable(values){
        setRow(values)
      }

      function getTransactions(user){
        console.log(user)
        if(user.length != 0){
          fetch("https://payment.trkmyclick.com/ccare/getTransactions", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({email:user[0].email})
            })
            .then(response => response.json()) 
            .then(response => {
              console.log(response)
              setTransactionsRows(response)
            })
            .catch((err)=>{
                console.log(err)
            })
        }
       
      }

      const columns: GridColDef[] = [
        { field: "cardholderName", headerName: "Customer Name",flex: 1 },
        { field: "email", headerName: "Email",flex: 1 },
        { field: "dataTransazione", headerName: "First transaction",flex: 1 },
        { field: "prezzo", headerName: "Price",flex: 1 },
        { field: "last4", headerName: "Last 4",flex: 1 },
        { field: "first6", headerName: "First 6",flex: 1 },
        { field: "clickid", headerName: "Clickid",flex: 1 },
        
       
      ];


      const transactionsColumns: GridColDef[] = [
        { field: "chargeId", headerName: "Charge Id",flex: 1 },
        { field: "dataTransazione", headerName: "Transaction Date",flex: 1 },
        { field: "prezzo", headerName: "Price",flex: 1 },
        { field: "nomePiano", headerName: "Plan Name",flex: 1 },
        { field: "transactionResult", headerName: "Result",flex: 1 },
        { field: "failureMessage", headerName: "Message",flex: 1 },
        { field:"tipologia",headerName:"Action",renderCell: (params)=>{
            if(params.row.tipologia == "abbonamento" && params.row.transactionResult == "SUCCESS"){
              return (
                <Button onClick={()=>unsubUser(params.row)} variant="contained" color="error">UNSUB</Button>
              )
            }else if(params.row.data_unsub){
              return (
                <Button disabled variant="contained" color="success">CANCELLED</Button>
              )
            }else{
              return <></>
            }
        }}
        
      ];


        return (
          <>
           <Dialog onClose={()=>setOpen(false)} open={open}>
                <DialogTitle>{popup.title}</DialogTitle>
                <div>{popup.body}</div>
            </Dialog>
          <Container>
          <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
              <FormCcare
                dataSet={(values) => {populateTable(values) }}
                emptydata={()=>{setRow([]);setTransactionsRows([])}}
              />
            </Box>
            </Container>
           <Grid container spacing={2}>
          
            <Grid item xs={6}>
                <DataGrid 
                    rows={rows} 
                    columns={columns}
                    autoHeight
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRowData = rows.filter((row) =>
                        selectedIDs.has(row.id.toString())
                      );
                      getTransactions(selectedRowData);
                    }}
                  />
              </Grid>
              <Grid item xs={6}>
                <DataGrid 
                    rows={transactionsRows} 
                    columns={transactionsColumns}
                    autoHeight
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'dataTransazione', sort: 'asc' }],
                      },
                    }}
                  />
              </Grid>
        
           
          </Grid>
          
         </>     
       
          
        );
}




